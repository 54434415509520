import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Elfonze Unites for a Viksit Bharat!"
        paragraph='Tricolor Décor, Cultural Performances and a Vision for 2047!!!'
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/elf_insider/independance_day_temapic.jpg" alt="Elfonze Unites for a Viksit Bharat!" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                              Elfonze Technologies celebrated India's 78th Independence Day at our Bengaluru DC with vibrant tricolour decorations and employees dressed in colourful ethnic attire, embodying the spirit of unity in diversity. The day was filled with lively activities, including traditional dance performances, engaging mono acting, a data analytics game and an exciting quiz that brought everyone together. Employees also enjoyed a mix of divine and filmy songs, adding a fun and festive atmosphere. The highlight was a collective oath to contribute towards a Viksit Bharat—a Developed India by 2047, ready to serve the global community. The celebration showcased not just patriotism but a shared dedication to driving the country’s growth and serving the world, proving that Elfonze is a community united in building a stronger, more prosperous India.
                          </p>
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                            <a href="#0">#independenceday</a>,
                            <a href="#0">#india</a>,
                            <a href="#0">#viksitbharat</a>,
                            <a href="#0">#78yearsofindependence</a>,
                            <a href="#0">#ethnicday</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Unites for a Viksit Bharat!</title>
      <meta key="description" name="description"
        content='Tricolor Décor, Cultural Performances and a Vision for 2047!!!' />
    </>
  )
}

export default BlogDetailsDark;
